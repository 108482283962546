/**
 * Created by lsubramaniam on 11/2/17.
 */
var OptInResponseModel = /** @class */ (function () {
    function OptInResponseModel() {
        this.status = '';
        this.message = '';
    }
    return OptInResponseModel;
}());
export default OptInResponseModel;
