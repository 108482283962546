/**
 * Created by lsubramaniam on 11/2/17.
 */
var SiteDetailModel = /** @class */ (function () {
    function SiteDetailModel() {
        this.orderPageId = '';
        this.trackerUrl = '';
        //campaignInfo: CampaignInfo = new CampaignInfo;
        this.offerChainDetails = [];
        this.offerChainId = '';
        this.archivedCampaignRedirectUrl = '';
        this.isCampaignArchived = false;
        this.success = false;
        this.brandId = '';
        this.storeFavionUrl = '';
        this.storePageTitle = '';
        this.googleTagManagerId = '';
        this.listTrackId = '';
        this.mixPanelId = '';
        this.monetateId = '';
        this.currentEnv = '';
        this.brandExpiryUrl = '';
        this.billingDescriptor = '';
        this.redirectPromotionalEffortUrl = '';
        this.freeEletterPubs = '';
    }
    return SiteDetailModel;
}());
export default SiteDetailModel;
