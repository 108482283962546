export const environment = {
  APP_ENV: 'PROD',
  production: true,
  env: 'prod',
  SITE_DETAILS_API: '/site/api/v1/getSiteDetails',
  ASSET_DETAILS_API: 'https://gsdpeazjjf.execute-api.us-east-1.amazonaws.com/prod',
  CREATE_ELETTER_API: '/leadsInternal/v1/web/createEletter',
  CREATE_HOTLIST_API: '/leadsInternal/v1/web/createHotList',
  CREATE_COI_API: '/leadsInternal/v1/web/coiOptin',
  DOUBLE_OPTIN_SEND_EMAIL_API: '/leadsInternal/v1/web/doubleOptinSendEmail',
  EVENTSTREAM_API: '/snowflake/generic',
  EVENTSTREAM_API_KEY: 'g5wZnpMOAV6d3DVWjACKU9Pz86tj10Es39Avoa3n',
  DATADOG_CLIENT_TOKEN: 'pub2a1eacb4678c8ac666513170783471aa',
  BSS_PIXEL_SRC: '/store/PROD/js/bss-px.min.js'
};
