import {Injectable} from '@angular/core';

@Injectable()
export class LoggerService {

  constructor() {
  }

  /**
   * log method to log the custom events to datadog.
   * @param message The message of your log that is fully indexed by Datadog.
   * @param messageAttributes A valid JSON object that includes all attributes attached to the MESSAGE.
   * @example logger('Button clicked', { name: 'buttonName', id: 123 });
   */
  log(message: string, messageAttributes?: {}) {
    console.log(message, messageAttributes);
  }

  /**
   * Debug method to log the custom events to datadog.
   * @param message The message of your log that is fully indexed by Datadog.
   * @param messageAttributes A valid JSON object that includes all attributes attached to the MESSAGE.
   * @example logger('Button clicked', { name: 'buttonName', id: 123 });
   */
  debug(message: string, messageAttributes?: {}) {
    console.debug(message, messageAttributes);
  }

  /**
   * Info method to log the custom events to datadog.
   * @param message The message of your log that is fully indexed by Datadog.
   * @param messageAttributes A valid JSON object that includes all attributes attached to the MESSAGE.
   * @example logger('Button clicked', { name: 'buttonName', id: 123 });
   */
  info(message: string, messageAttributes?: {}) {
    console.info(message, messageAttributes);
  }

  /**
   * warn method to log the custom events to datadog.
   * @param message The message of your log that is fully indexed by Datadog.
   * @param messageAttributes A valid JSON object that includes all attributes attached to the MESSAGE.
   * @example logger('Button clicked', { name: 'buttonName', id: 123 });
   */
  warn(message: string, messageAttributes: {}) {
    console.warn(message, messageAttributes);
  }

  /**
   * error method to log the custom events to datadog.
   * @param message The message of your log that is fully indexed by Datadog.
   * @param messageAttributes A valid JSON object that includes all attributes attached to the MESSAGE.
   * @example logger('Button clicked', { name: 'buttonName', id: 123 });
   */
  error(message: string, messageAttributes: {}) {
    console.error(message, messageAttributes);
  }
}
