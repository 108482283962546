import {SiteDetail} from "./site-detail";
import {CampaignInfo} from "./campaign-info";
import {OfferChainDetail} from "./offer-chain-detail";
/**
 * Created by lsubramaniam on 11/2/17.
 */


export default class SiteDetailModel implements SiteDetail {
  orderPageId: string = '';
  trackerUrl: string = '';
  //campaignInfo: CampaignInfo = new CampaignInfo;
  offerChainDetails: OfferChainDetail[] = [];
  offerChainId: string = '';
  archivedCampaignRedirectUrl: string = '';
  isCampaignArchived: boolean = false;
  success: boolean = false;
  brandId: string = '';
  storeFavionUrl: string = '';
  storePageTitle: string = '';
  googleTagManagerId: string = '';
  listTrackId: string = '';
  mixPanelId: string = '';
  monetateId: string = '';
  currentEnv: string = '';
  brandExpiryUrl: string = '';
  billingDescriptor: string = '';
  campaignType: string;
  campaignCss?: string | '';
  campaignJs?: string | '';
  redirectPromotionalEffortUrl : string = '';
  freeEletterPubs: string = '';
  externalJavaScriptFiles?: string | '';
  cookieConsentDomainScriptId?: string | null;
  constructor() {}
}
