var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/**
 * Created by lsubramaniam on 10/29/17.
 */
// Component is a decorator imported from @angular/core
import { OnInit } from '@angular/core';
import { SiteManagerService } from './site-manager.service';
import { concat, Observable } from 'rxjs';
import { Md5 } from 'ts-md5/dist/md5';
import { Title } from '@angular/platform-browser';
import { GlobalDataService } from '../../common/util/globaldata.service';
import { UtilityService } from '../../common/util/utils.service';
import OptInResponseModel from '../../models/optin-response-model';
import SiteDetailModel from '../../models/site-detail-model';
import { TrackerService } from '../../services/tracker-service';
import { ActivityType } from '../../models/activity-type';
import { EventList } from '../../common/eventlistener/event-list';
import { HttpClient } from '@angular/common/http';
import * as uuid from 'uuid';
import { LoggerService } from '../../services/logger.service';
import { environment } from '../../../environments/environment';
import { ExternalJsSupportService } from 'src/app/services/externalJsSupport.service';
// use of the decorator to register the component
var SiteManagerComponent = /** @class */ (function () {
    function SiteManagerComponent(siteManagerService, utilityService, globalDataService, titleService, trackerService, logger, http, externalJs) {
        this.siteManagerService = siteManagerService;
        this.utilityService = utilityService;
        this.globalDataService = globalDataService;
        this.titleService = titleService;
        this.trackerService = trackerService;
        this.logger = logger;
        this.http = http;
        this.externalJs = externalJs;
        this._siteDetail = new SiteDetailModel();
        this.htmlContent = '';
        this.currentCssLinks = [];
        this.currentCssInline = '';
        this.currentJsLinks = [];
        this.currentJsInline = '';
        this.styleIds = [];
        this.jsIds = [];
        this._optInResponse = new OptInResponseModel();
        this.isProcessing = false;
    }
    SiteManagerComponent.prototype.ngOnInit = function () {
        this.globalDataService.sessionId = uuid.v4();
        this.utilityService.setUpQueryStringDetails();
        this.setupSiteDetails();
    };
    SiteManagerComponent.prototype.setTitle = function (newTitle) {
        this.titleService.setTitle(newTitle);
    };
    SiteManagerComponent.prototype.onNextPageClickEvent = function (action) {
        this.utilityService.loadNextAsset(action);
        this.loadAsset();
        this.trackerService.track(ActivityType.click);
    };
    SiteManagerComponent.prototype.onSingleOptinClickEvent = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            var coiOptInConfirmation, coiOptInConfirmationAssetId;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.trackerService.track(ActivityType.click);
                        this.isProcessing = true;
                        this.globalDataService.email = event.email;
                        // Dispatch Event For SingleOptinClick
                        EventService.dispatchEvent(EventList.SingleOptinClick, null, event);
                        if (!this.globalDataService.asset.isCoiOptin) return [3 /*break*/, 2];
                        this.logger.log("Started Processing COI optin for email " + this.globalDataService.email);
                        coiOptInConfirmation = this.utilityService.getContentByOfferIdAndType(this.globalDataService.asset.sourceOfferId, 'CoiOptInConfirmation');
                        coiOptInConfirmationAssetId = '';
                        if (coiOptInConfirmation) {
                            coiOptInConfirmationAssetId = coiOptInConfirmation.sourceParentAssetId;
                        }
                        return [4 /*yield*/, this.processSingleOptinUtil(event, coiOptInConfirmationAssetId, 'coi')];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 2:
                        this.logger.log("Started Processing Single optin for email " + this.globalDataService.email);
                        return [4 /*yield*/, this.processSingleOptinUtil(event, '', 'single')];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    SiteManagerComponent.prototype.redirectToPromoUrl = function () {
        if (this.globalDataService.site && this.globalDataService.site.redirectPromotionalEffortUrl) {
            var redirectUrl = this.utilityService.updateQueryStringParamForUrl(this.globalDataService.site.redirectPromotionalEffortUrl, 'sourceeid', this.globalDataService.queryParams.effortId);
            if (this.globalDataService.queryParams.src) {
                redirectUrl = this.utilityService.updateQueryStringParamForUrl(redirectUrl, 'src', this.globalDataService.queryParams.src);
            }
            window.location.replace(redirectUrl);
        }
    };
    SiteManagerComponent.prototype.onDoubleOptinClickEvent = function (event) {
        var _this = this;
        this.trackerService.track(ActivityType.click);
        this.globalDataService.email = event.email;
        var dblOptInConfirmation = this.utilityService.getContentByOfferIdAndType(this.globalDataService.asset.sourceOfferId, 'DoubleOptInConfirmation');
        var dblOptInConfirmationAssetId = '';
        if (dblOptInConfirmation) {
            dblOptInConfirmationAssetId = dblOptInConfirmation.sourceParentAssetId;
        }
        this.logger.log("Started Processing Double optin for email " + this.globalDataService.email);
        this.isProcessing = true;
        // Dispatch Event For DoubleOptinClick
        EventService.dispatchEvent(EventList.DoubleOptinClick, null, event);
        this.siteManagerService.doubleOptIn(event, dblOptInConfirmationAssetId, '', true).subscribe(function (responseInfo) {
            _this.logger.log("Finished Processing Double optin for email " + _this.globalDataService.email);
            if (responseInfo[0] != null && responseInfo[0].success) {
                _this.isProcessing = false;
                event.isNewUser = (responseInfo[0].newUser) ? true : false;
                // Dispatch Event For DoubleOptinDone
                EventService.dispatchEvent(EventList.DoubleOptinDone, null, event);
                _this.utilityService.loadNextAsset('Submit');
                _this.loadAsset();
                _this.trackerService.track(ActivityType.conv);
            }
            else {
                if (responseInfo[0] != null && responseInfo[0].error && responseInfo[0].error === 'InvalidEmail') {
                    $('.sna-email-invalid').show();
                }
            }
        }, function (error) {
            _this.logger.error("Finished Processing Double optin for email " + _this.globalDataService.email, error.error);
        });
    };
    SiteManagerComponent.prototype.loadAsset = function () {
        var _this = this;
        try {
            var assetLocation_1 = this.getAssetLocation();
            // if there are no other asset then we clearStyles.
            // otherwise to clear style after we get the new asset and load it to reduce the times getting the new asset
            if (!this.globalDataService.asset || !assetLocation_1) {
                // clear All css
                this.clearStyles();
            }
            this.logger.log('Cleared out old Asset');
            if (this.globalDataService.asset) {
                if (assetLocation_1) {
                    this.isProcessing = true;
                    this.siteManagerService.getAsset(assetLocation_1).subscribe(function (responseInfo) {
                        if (responseInfo.indexOf('sna-email') > 0) {
                            _this.globalDataService.isCoreg = true;
                        }
                        _this.isProcessing = false;
                        // clear All css
                        _this.clearStyles();
                        // we receive a json object, we have to extract the string
                        _this.setUpHtmlContentJavascriptAndCss().subscribe(function () {
                            try {
                                _this.processDoubleOptInConfirmation();
                                _this.processCoiOptInConfirmation();
                                _this.processSingleOptInConfirmation();
                                _this.trackerService.track(ActivityType.imp);
                                _this.logger.log("Finished loading of asset  " + assetLocation_1 + " ");
                            }
                            catch (error) {
                                _this.logger.error('Error loading asset ', error);
                            }
                        }, function (error) {
                            _this.logger.error('Error subscribing to Setup HTML JS & CSS Content. ', error);
                        });
                    }, function (error) {
                        // clear All css
                        _this.clearStyles();
                        _this.logger.error('Error getting asset from the s3. ', error.error);
                    });
                }
            }
        }
        catch (error) {
            this.logger.error('Exception loading asset ', error);
        }
    };
    SiteManagerComponent.prototype.onInvalidUserInfo = function (invalidFields) {
    };
    SiteManagerComponent.prototype.setupSiteDetails = function () {
        var _this = this;
        this.isProcessing = true;
        this.siteManagerService.getSiteDetails().subscribe(function (responseInfo) {
            try {
                _this.isProcessing = false;
                _this._siteDetail = responseInfo;
                if (_this._siteDetail) {
                    _this.globalDataService.site = _this._siteDetail;
                    _this.loadSite();
                }
            }
            catch (error) {
                _this.logger.error('Exception getting site details. ', error);
            }
        }, function (error) {
            _this.logger.error('Error Subscribing to get site details. ', error.error);
        });
    };
    SiteManagerComponent.prototype.loadSite = function () {
        var selOfferChainId = this.utilityService.getSelectedOfferChainId(this._siteDetail);
        if (this._siteDetail) {
            this.insertCookieConsentScript(this.setUpOsanoListner);
            if (this._siteDetail && this._siteDetail.isCampaignArchived) {
                this.loadArchiveOrOfferExpiryPage();
            }
            else {
                this.insertAppScripts();
                this.utilityService.getAssetDetail(this._siteDetail, selOfferChainId);
                this.externalJs.triggerExternalJsFiles();
                this.loadAsset();
                this.trackerService.track(ActivityType.landingPage);
                this.loadFavicon();
                this.loadPageTitle();
                this.initTrackingContainerScripts();
                this.loadBSSPixel(this.globalDataService.site.brandId);
            }
        }
    };
    SiteManagerComponent.prototype.loadArchiveOrOfferExpiryPage = function () {
        this.getCampaignArchiveOrOfferExpiredPage();
    };
    SiteManagerComponent.prototype.getCampaignArchiveOrOfferExpiredPage = function () {
        var isArchivedUrlEmpty = '';
        if (this._siteDetail.archivedCampaignRedirectUrl) {
            isArchivedUrlEmpty = this._siteDetail.archivedCampaignRedirectUrl;
        }
        if (isArchivedUrlEmpty && !this.utilityService.contains(this._siteDetail.archivedCampaignRedirectUrl, this.globalDataService.queryParams.campaignId)) {
            window.top.location.href = this._siteDetail.archivedCampaignRedirectUrl;
        }
        else {
            this.getOfferExpiryPageContent();
        }
        return null;
    };
    SiteManagerComponent.prototype.getOfferExpiryPageContent = function () {
        var _this = this;
        var expiryPage = '';
        if (this._siteDetail.brandExpiryUrl) {
            expiryPage = this._siteDetail.brandExpiryUrl;
        }
        else {
            expiryPage = this.getDefaultOfferExpiryPage();
        }
        this.getOfferExpiredPage(expiryPage).subscribe(function (data) {
            if (data) {
                _this.htmlContent = data;
                // this.setHtmlContent(data);
            }
        }, function (error) {
            console.log(error);
        });
    };
    SiteManagerComponent.prototype.getDefaultOfferExpiryPage = function () {
        return 'https://marketingassets.cloudsna.com/dev/templates/OfferEndedPage/offer_ended_page.html';
    };
    SiteManagerComponent.prototype.getOfferExpiredPage = function (expiryPage) {
        return this.http.get(expiryPage, { responseType: 'text' });
    };
    SiteManagerComponent.prototype.initTrackingContainerScripts = function () {
        this.loadGoogleTagManager();
        this.loadMixPanel();
    };
    SiteManagerComponent.prototype.loadGoogleTagManager = function () {
        if (this._siteDetail.googleTagManagerId) {
            loadGTMScript(this._siteDetail.googleTagManagerId);
        }
    };
    SiteManagerComponent.prototype.loadMixPanel = function () {
        if (this._siteDetail.mixPanelId) {
            mixpanel.init(this._siteDetail.mixPanelId);
        }
    };
    SiteManagerComponent.prototype.loadPageTitle = function () {
        if (this._siteDetail.storePageTitle) {
            this.setTitle(this._siteDetail.storePageTitle);
        }
    };
    SiteManagerComponent.prototype.loadFavicon = function () {
        if (this._siteDetail.storeFavionUrl) {
            this.setAppFavicon(this._siteDetail.storeFavionUrl);
        }
    };
    SiteManagerComponent.prototype.setAppFavicon = function (path) {
        $('#faviconLogo').attr('href', path);
    };
    SiteManagerComponent.prototype.setUpHtmlContentJavascriptAndCss = function () {
        var _this = this;
        return new Observable(function (subscriber) {
            try {
                var setupCampaignCss$ = _this.loadCampaignCss();
                var setupCampaignJs$ = _this.loadCampaignJs();
                var setupExternalCss$ = _this.setupExternalCss();
                var setupExternalJavascript$ = _this.setupExternalJavascript();
                var setupCssLinks$ = _this.setupInlineCss();
                var setupJavascriptLinks$ = _this.setupInlineJavascript();
                var setupHtml$ = _this.setupPageHtml();
                var setupLandingPage$ = concat(setupCampaignCss$, setupCampaignJs$, setupExternalCss$, setupCssLinks$, setupHtml$, setupExternalJavascript$, setupJavascriptLinks$);
                var doneCounter_1 = 0;
                setupLandingPage$.subscribe(function () {
                    doneCounter_1++;
                    if (doneCounter_1 === 5) {
                        setTimeout(function () {
                            subscriber.next();
                            subscriber.complete();
                        }, 300);
                    }
                }, function (error) {
                    _this.logger.error('Error Setting up Landing page. ', error);
                });
            }
            catch (error) {
                _this.logger.error('Exception from setting up Page Content. ', error);
            }
        });
    };
    SiteManagerComponent.prototype.loadCampaignCss = function () {
        var _this = this;
        return new Observable(function (subscriber) {
            try {
                var campaignCssLinks = SiteManagerComponent.parseCampaignAssetLinks(_this.globalDataService.site.campaignCss);
                campaignCssLinks.forEach(function (cssLink) {
                    var idAttribute = Md5.hashStr(cssLink).toString();
                    _this.addCss(idAttribute, cssLink.trim());
                });
                subscriber.next();
            }
            catch (error) {
                console.error('Failed to add campaign css: ', error);
                subscriber.error(error);
            }
            finally {
                subscriber.complete();
            }
        });
    };
    SiteManagerComponent.prototype.loadCampaignJs = function () {
        var _this = this;
        return new Observable(function (subscriber) {
            try {
                var campaignJsLinks = SiteManagerComponent.parseCampaignAssetLinks(_this.globalDataService.site.campaignJs);
                campaignJsLinks.forEach(function (jsLink, index) {
                    var idAttribute = 'campaignJs' + index;
                    _this.addJs(idAttribute, jsLink.trim(), 'top');
                });
                subscriber.next();
            }
            catch (error) {
                console.error('Failed to add campaign js: ', error);
                subscriber.error(error);
            }
            finally {
                subscriber.complete();
            }
        });
    };
    SiteManagerComponent.prototype.setupExternalCss = function () {
        var _this = this;
        return new Observable(function (subscriber) {
            try {
                _this.currentCssLinks = [];
                var links = _this.globalDataService.asset.destExternalCss;
                if (links) {
                    _this.currentCssLinks = _this.parseAssetExternalField(links);
                    if (_this.currentCssLinks) {
                        _this.currentCssLinks.forEach(function (cssLink) {
                            if (cssLink && cssLink.uri) {
                                var fileHash = Md5.hashStr(cssLink.uri).toString();
                                _this.addCss(fileHash, cssLink.uri);
                            }
                        });
                    }
                }
                subscriber.next(_this.currentCssLinks);
                subscriber.complete();
            }
            catch (error) {
                subscriber.error(error);
                subscriber.complete();
            }
        });
    };
    SiteManagerComponent.prototype.setupExternalJavascript = function () {
        var _this = this;
        return new Observable(function (subscriber) {
            try {
                _this.currentJsLinks = [];
                var jsUrls = _this.globalDataService.asset.destExternalJavacript;
                if (jsUrls) {
                    _this.currentJsLinks = _this.parseAssetExternalField(jsUrls);
                    if (_this.currentJsLinks) {
                        _this.currentJsLinks.forEach(function (jsLink) {
                            if (jsLink && jsLink.uri) {
                                var fileHash = Md5.hashStr(jsLink.uri).toString();
                                _this.addJs(fileHash, jsLink.uri, jsLink.position);
                            }
                        });
                    }
                }
                subscriber.next(_this.currentJsLinks);
                subscriber.complete();
            }
            catch (error) {
                subscriber.error(error);
                subscriber.complete();
            }
        });
    };
    SiteManagerComponent.prototype.setupInlineJavascript = function () {
        var _this = this;
        return new Observable(function (subscriber) {
            try {
                _this.currentJsInline = '';
                var inlineJavascript = _this.globalDataService.asset.destInlineJavascript;
                if (inlineJavascript) {
                    var fileHash = Md5.hashStr(inlineJavascript).toString();
                    var inlineJSNoCache = _this.utilityService.getVersionedUrl(inlineJavascript);
                    _this.addJs(fileHash, inlineJSNoCache, 'top');
                }
                subscriber.next(_this.currentJsLinks);
                subscriber.complete();
            }
            catch (error) {
                subscriber.error(error);
                subscriber.complete();
            }
        });
    };
    SiteManagerComponent.prototype.setupInlineCss = function () {
        var _this = this;
        return new Observable(function (subscriber) {
            try {
                _this.currentCssInline = '';
                var inLineCss = _this.globalDataService.asset.destInlineCss;
                if (inLineCss) {
                    if (inLineCss.includes('.css')) {
                        var fileHash = Md5.hashStr(inLineCss).toString();
                        var inLineCssNoCache = _this.utilityService.getVersionedUrl(inLineCss);
                        _this.addCss(fileHash, inLineCssNoCache);
                    }
                    else {
                        _this.currentCssInline = atob(inLineCss);
                        if (_this.currentCssInline) {
                            _this.addInLineCss(_this.currentCssInline);
                        }
                    }
                }
                subscriber.next(_this.currentCssInline);
                subscriber.complete();
            }
            catch (error) {
                subscriber.error(error);
                subscriber.complete();
            }
        });
    };
    SiteManagerComponent.parseCampaignAssetLinks = function (links) {
        if (links) {
            return links.trim().split(this.CAMPAIGN_LINKS_SEPARATOR);
        }
        return [];
    };
    SiteManagerComponent.prototype.parseAssetExternalField = function (field) {
        if (field) {
            return JSON.parse(atob(field));
        }
        return field;
    };
    SiteManagerComponent.prototype.setupPageHtml = function () {
        var _this = this;
        return new Observable(function (subscriber) {
            try {
                var assetUrl = _this.globalDataService.asset.destAssetLocation;
                _this.siteManagerService.getAsset(assetUrl).subscribe(function (htmlContent) {
                    _this.htmlContent = htmlContent;
                    _this.appendUrl(_this.globalDataService.asset.destParentAssetId);
                    _this.externalJs.fireReloadAssetData();
                    subscriber.next();
                    subscriber.complete();
                }, function (error) {
                    subscriber.error(error.error);
                    subscriber.complete();
                });
            }
            catch (error) {
                subscriber.error(error);
                subscriber.complete();
            }
        });
    };
    SiteManagerComponent.prototype.addInLineCss = function (cssScript) {
        var head = document.getElementsByTagName('head')[0];
        var styleElement = document.createElement('style');
        styleElement.textContent = cssScript;
        styleElement.type = 'text/css';
        head.appendChild(styleElement);
    };
    SiteManagerComponent.prototype.addCss = function (id, cssLink) {
        var head = document.getElementsByTagName('head')[0];
        var link = document.createElement('link');
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = cssLink;
        link.id = id;
        head.appendChild(link);
        this.styleIds.push(id);
    };
    SiteManagerComponent.prototype.addJs = function (id, jsUrl, position) {
        var script = document.createElement('script');
        script.src = jsUrl;
        script.id = id;
        if (position === 'top') {
            var head = document.getElementsByTagName('head')[0];
            head.appendChild(script);
        }
        else {
            document.body.appendChild(script);
        }
        if (id) {
            this.styleIds.push(id);
        }
    };
    SiteManagerComponent.prototype.clearStyles = function () {
        if (this.styleIds == null) {
            this.styleIds = new Array();
            return;
        }
        this.styleIds.forEach(function (id) {
            var styleElement = document.getElementById(id);
            if (styleElement) {
                styleElement.parentNode.removeChild(styleElement);
            }
        });
    };
    SiteManagerComponent.prototype.processDoubleOptInConfirmation = function () {
        var _this = this;
        if (this.globalDataService.queryParams.doubleOptInParams) {
            this.siteManagerService
                .doubleOptIn(null, this.globalDataService.queryParams.assetId, this.globalDataService.queryParams.doubleOptInParams, false)
                .subscribe(function (responseInfo) {
                // ToDo Check response and  show error message.
                if (_this.globalDataService.site && _this.globalDataService.site.redirectPromotionalEffortUrl) {
                    _this.redirectToPromoUrl();
                }
                if (responseInfo && responseInfo.encryptedSnaid) {
                    _this.globalDataService.encryptedSnaid = responseInfo.encryptedSnaid;
                }
            }, function (error) {
                _this.logger.error("Error Processing Double Optin confirmation for email " + _this.globalDataService.user.email, error);
            });
        }
    };
    SiteManagerComponent.prototype.processCoiOptInConfirmation = function () {
        var _this = this;
        if ((this.globalDataService.queryParams.coi || this.globalDataService.queryParams.coiParams) && this.globalDataService.user) {
            this.siteManagerService.coiOptIn(this.globalDataService.user, '', false).subscribe(function (responseInfo) {
                // ToDo Check response and  show error message.
                // Dispatch Event For SingleOptinClick
                if (responseInfo && responseInfo.encryptedSnaid) {
                    _this.globalDataService.encryptedSnaid = responseInfo.encryptedSnaid;
                }
                EventService.dispatchEvent(EventList.CoiOptinDone, null, {
                    'email': _this.globalDataService.user.email,
                    'firstName': _this.globalDataService.user.firstName,
                    'lastName': _this.globalDataService.user.lastName,
                    'street': _this.globalDataService.user.street,
                    'city': _this.globalDataService.user.city,
                    'state': _this.globalDataService.user.state,
                    'zipcode': _this.globalDataService.user.zipcode,
                    'country': _this.globalDataService.user.country,
                    'effortId': _this.globalDataService.queryParams.effortId,
                    'campaignId': _this.globalDataService.queryParams.campaignId
                });
            }, function (error) {
                _this.logger.error("Error Processing COI Optin confirmation for email " + _this.globalDataService.user.email, error);
            });
        }
    };
    SiteManagerComponent.prototype.processSingleOptInConfirmation = function () {
        var _this = this;
        if (this.globalDataService.user && this.globalDataService.user.email && !this.globalDataService.isCoreg && !this.globalDataService.queryParams.coi) {
            var validEmail = this.utilityService.isValidEmail(this.globalDataService.user.email);
            if (validEmail) {
                this.siteManagerService.singleOptIn(this.globalDataService.user, '').subscribe(function (responseInfo) {
                    if (_this.globalDataService.site && _this.globalDataService.site.redirectPromotionalEffortUrl) {
                        _this.redirectToPromoUrl();
                    }
                    if (responseInfo && responseInfo.encryptedSnaid) {
                        _this.globalDataService.encryptedSnaid = responseInfo.encryptedSnaid;
                    }
                }, function (error) {
                    _this.logger.error("Error Processing Single Optin confirmation for email " + _this.globalDataService.user.email, error);
                });
            }
        }
        else if (this.globalDataService.queryParams.oneClick && this.globalDataService.queryParams.oneClick === 'true') {
            this.redirectToPromoUrl();
        }
    };
    SiteManagerComponent.prototype.getAssetLocation = function () {
        var assetLocation = '';
        if (this.globalDataService.queryParams.doubleOptInParams && this.globalDataService.asset.sourcePageRedirectUrl) {
            assetLocation = this.globalDataService.asset.sourcePageRedirectUrl;
        }
        else {
            assetLocation = this.globalDataService.asset.destAssetLocation;
        }
        this.logger.log("Start loadAsset of type " + this.globalDataService.asset.destAssetType + " asset location " + assetLocation + " ");
        return assetLocation;
    };
    SiteManagerComponent.prototype.appendUrl = function (value) {
        this.utilityService.updateQueryStringParam('assetId', value);
    };
    SiteManagerComponent.prototype.processSingleOptinUtil = function (event, coiOptInConfirmationAssetId, optinType) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.isProcessing = true;
                this.siteManagerService.singleOptIn(event, coiOptInConfirmationAssetId).subscribe(function (responseInfo) {
                    _this.logger.log("Finished Processing " + optinType + " optin for email " + _this.globalDataService.email);
                    _this.isProcessing = false;
                    if (responseInfo[0] != null && responseInfo[0].success) {
                        if (responseInfo[0].encryptedSnaid) {
                            _this.globalDataService.encryptedSnaid = responseInfo[0].encryptedSnaid;
                        }
                        _this.trackerService.track(ActivityType.conv);
                        _this.utilityService.loadNextAsset('Submit');
                        if (optinType !== 'coi') {
                            event.isNewUser = (responseInfo[0].newUser) ? true : false;
                            // Dispatch Event For SingleOptinDone
                            EventService.dispatchEvent(EventList.SingleOptinDone, null, event);
                        }
                        if (_this.globalDataService.site && _this.globalDataService.site.redirectPromotionalEffortUrl) {
                            _this.redirectToPromoUrl();
                        }
                        else {
                            _this.loadAsset();
                        }
                    }
                    else {
                        var responseError = responseInfo[0] && responseInfo[0].error;
                        if (responseError === 'InvalidEmail') {
                            $('.sna-email-invalid').show();
                        }
                    }
                }, function (error) {
                    _this.logger.error("Error Prcoessing " + optinType + " optin for email " + _this.globalDataService.email + " ", error.error);
                });
                return [2 /*return*/];
            });
        });
    };
    SiteManagerComponent.prototype.createScriptTag = function (src, id, brandId) {
        if (id && src) {
            var head = document.getElementsByTagName('head').item(0);
            var script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = src;
            script.id = id;
            if (brandId) {
                script.setAttribute('data-brandId', brandId);
                // tslint:disable-next-line:radix
                var brandInt = parseInt(brandId);
                script.setAttribute('data-affiliateId', "" + (brandInt - (brandInt % 100)));
            }
            head.appendChild(script);
        }
    };
    SiteManagerComponent.prototype.loadBSSPixel = function (brandId) {
        this.createScriptTag(environment.BSS_PIXEL_SRC, 'bss-pixel', brandId);
    };
    SiteManagerComponent.prototype.insertAppScripts = function () {
        this.addJs('', 'https://maxcdn.bootstrapcdn.com/bootstrap/3.3.5/js/bootstrap.min.js', 'top');
        this.addCss('', 'assets/bootstrap.min.css');
        this.addCss('', 'assets/bootstrap-theme.min.css');
        this.addCss('', 'https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css');
    };
    SiteManagerComponent.prototype.insertCookieConsentScript = function (callBack) {
        if (this._siteDetail.cookieConsentDomainScriptId) {
            var head = document.getElementsByTagName('head')[0];
            var script = document.createElement('script');
            script.src = this._siteDetail.cookieConsentDomainScriptId;
            script.onload = callBack;
            head.insertBefore(script, head.firstChild);
        }
    };
    SiteManagerComponent.prototype.setUpOsanoListner = function () {
        Osano.cm.addEventListener('osano-cm-initialized', function () {
            console.log('[osano] script initialized...');
            if (Osano.cm.countryCode === 'us') {
                console.log('[osano] hiding dialog...');
                Osano.cm.hideDialog();
            }
        });
    };
    SiteManagerComponent.CAMPAIGN_LINKS_SEPARATOR = ';';
    return SiteManagerComponent;
}());
export { SiteManagerComponent };
