/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./asset-manager.component";
import * as i2 from "../../common/util/globaldata.service";
import * as i3 from "../../common/util/utils.service";
var styles_AssetManagerComponent = [];
var RenderType_AssetManagerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AssetManagerComponent, data: {} });
export { RenderType_AssetManagerComponent as RenderType_AssetManagerComponent };
export function View_AssetManagerComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { assetManagerElement: 0 }), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["assetManagerElement", 1]], null, 0, "div", [], null, null, null, null, null))], null, null); }
export function View_AssetManagerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "asset-manager", [], null, null, null, View_AssetManagerComponent_0, RenderType_AssetManagerComponent)), i0.ɵdid(1, 114688, null, 0, i1.AssetManagerComponent, [i2.GlobalDataService, i3.UtilityService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AssetManagerComponentNgFactory = i0.ɵccf("asset-manager", i1.AssetManagerComponent, View_AssetManagerComponent_Host_0, { htmlContent: "htmlContent" }, { onNextPageClickEvent: "onNextPageClickEvent", onSingleOptinClickEvent: "onSingleOptinClickEvent", onDoubleOptinClickEvent: "onDoubleOptinClickEvent", onInvalidUserInfo: "onInvalidUserInfo", onCoiOptinClickEvent: "onCoiOptinClickEvent" }, []);
export { AssetManagerComponentNgFactory as AssetManagerComponentNgFactory };
