/**
 * Created by lsubramaniam on 4/16/18.
 */
import { EventList } from "./event-list";
import { EventListeners } from "./event-listeners";
var EventService = /** @class */ (function () {
    function EventService() {
        this._eventListenersList = [];
    }
    EventService.prototype.dispatchEvent = function (event, error, data) {
        var functionList = this.getEventListeners(event);
        for (var _i = 0, functionList_1 = functionList; _i < functionList_1.length; _i++) {
            var func = functionList_1[_i];
            func(error, data);
        }
    };
    EventService.prototype.addEventListener = function (event, callback) {
        this.pushEventListener(event, callback);
    };
    Object.defineProperty(EventService.prototype, "Events", {
        get: function () {
            return EventList;
        },
        enumerable: true,
        configurable: true
    });
    EventService.prototype.getEventListeners = function (event) {
        for (var _i = 0, _a = this._eventListenersList; _i < _a.length; _i++) {
            var eventListener = _a[_i];
            if (eventListener.event === event) {
                return eventListener.listeners;
            }
        }
        return [];
    };
    EventService.prototype.pushEventListener = function (event, callback) {
        for (var _i = 0, _a = this._eventListenersList; _i < _a.length; _i++) {
            var eventListener = _a[_i];
            if (eventListener.event === event) {
                eventListener.listeners.push(callback);
                break;
            }
        }
        var newEventListener = new EventListeners();
        newEventListener.event = event;
        newEventListener.listeners.push(callback);
        this._eventListenersList.push(newEventListener);
    };
    return EventService;
}());
export { EventService };
