import { ElementRef, EventEmitter, OnInit } from '@angular/core';
import { GlobalDataService } from '../../common/util/globaldata.service';
import { UtilityService } from '../../common/util/utils.service';
import { UserOptinInfo } from '../../models/user-optin-info';
var AssetManagerComponent = /** @class */ (function () {
    function AssetManagerComponent(gd, utilityService) {
        this.gd = gd;
        this.utilityService = utilityService;
        this.onNextPageClickEvent = new EventEmitter();
        this.onSingleOptinClickEvent = new EventEmitter();
        this.onDoubleOptinClickEvent = new EventEmitter();
        this.onInvalidUserInfo = new EventEmitter();
        this.onCoiOptinClickEvent = new EventEmitter();
    }
    Object.defineProperty(AssetManagerComponent.prototype, "htmlContent", {
        get: function () {
            return this._htmlContent;
        },
        set: function (value) {
            if (value) {
                this._htmlContent = value;
                this.assetManagerElement.nativeElement.innerHTML = value;
                this.setupListeners();
                this.populateUserInfo();
            }
        },
        enumerable: true,
        configurable: true
    });
    AssetManagerComponent.prototype.ngOnInit = function () { };
    AssetManagerComponent.prototype.setupSubmitListener = function (element) {
        this.setupNextPageListener(element);
    };
    AssetManagerComponent.prototype.setupListeners = function () {
        var element = this.assetManagerElement.nativeElement;
        this.setupOnBlurHideErrorsListener(element);
        this.setupSubmitListener(element);
    };
    AssetManagerComponent.prototype.setupOnBlurHideErrorsListener = function (element) {
        var _this = this;
        var querySelectorList = [];
        Object.keys(this.gd.fieldSelectors).forEach(function (field) {
            if (field === 'email' || field === 'zipcode' || field === 'phoneNumber')
                querySelectorList.push("" + _this.gd.fieldSelectors[field].fieldClass);
            else
                querySelectorList.push(_this.gd.fieldSelectors[field].fieldClass + ":required");
        });
        if (querySelectorList.length > 0) {
            var query = querySelectorList.join(', ');
            var requiredElements = element.querySelectorAll(query);
            this.bindOnBlurRemoveError(element, requiredElements);
        }
    };
    AssetManagerComponent.prototype.setupNextPageListener = function (element) {
        this.disableOptin();
        this.hideErrors();
        var nextPageLinkElements = element.querySelectorAll('.sna-next-page');
        this.bindOnNextPageClickEvent(element, nextPageLinkElements);
        var singleOptinElements = element.querySelectorAll('.sna-single-optin');
        this.bindOnSingleOptinClickEvent(element, singleOptinElements);
        var doubleOptinElements = element.querySelectorAll('.sna-double-optin');
        this.bindOnDoubleOptinClickEvent(element, doubleOptinElements);
    };
    AssetManagerComponent.prototype.bindOnNextPageClickEvent = function (element, nextPageLinkElements) {
        var _this = this;
        if (nextPageLinkElements.length) {
            for (var _i = 0, nextPageLinkElements_1 = nextPageLinkElements; _i < nextPageLinkElements_1.length; _i++) {
                var nextPageLinkElement = nextPageLinkElements_1[_i];
                nextPageLinkElement.addEventListener('click', function (event) {
                    _this.onNextPageClickEvent.emit('Next');
                });
            }
        }
    };
    AssetManagerComponent.prototype.bindOnBlurRemoveError = function (element, requiredElements) {
        var _this = this;
        if (requiredElements && requiredElements.length) {
            var _loop_1 = function (requiredElement) {
                requiredElement.addEventListener('blur', function (event) {
                    var target = event.target.closest('.sna-page-form') || element;
                    var field = _this.getFieldFromElement(requiredElement);
                    if (field) {
                        _this.hideError(field);
                        var invalidField = _this.invalidFields(target).find(function (f) { return f.field === field; });
                        if (invalidField) {
                            _this.showErrors([invalidField]);
                        }
                    }
                });
            };
            for (var _i = 0, requiredElements_1 = requiredElements; _i < requiredElements_1.length; _i++) {
                var requiredElement = requiredElements_1[_i];
                _loop_1(requiredElement);
            }
        }
    };
    AssetManagerComponent.prototype.getFieldFromElement = function (element) {
        var _this = this;
        var result = null;
        if (element && element.className) {
            element.className.split(' ').forEach(function (fieldClass) {
                Object.keys(_this.gd.fieldSelectors).forEach(function (field) {
                    if (_this.gd.fieldSelectors[field].fieldClass === "." + fieldClass) {
                        result = field;
                        return;
                    }
                });
                if (result) {
                    return;
                }
            });
        }
        return result;
    };
    AssetManagerComponent.prototype.getRequiredFields = function () {
        var _this = this;
        var querySelectorList = [];
        var requiredFields = [];
        Object.keys(this.gd.fieldSelectors).forEach(function (field) {
            querySelectorList.push(_this.gd.fieldSelectors[field].fieldClass + ":required");
        });
        if (querySelectorList.length > 0) {
            var query = querySelectorList.join(', ');
            var requiredElements = this.assetManagerElement.nativeElement.querySelectorAll(query);
            if (requiredElements && requiredElements.length) {
                for (var _i = 0, requiredElements_2 = requiredElements; _i < requiredElements_2.length; _i++) {
                    var requiredElement = requiredElements_2[_i];
                    requiredFields.push(this.getFieldFromElement(requiredElement));
                }
            }
        }
        return requiredFields;
    };
    AssetManagerComponent.prototype.bindOnSingleOptinClickEvent = function (element, singleOptinElements) {
        var _this = this;
        if (singleOptinElements.length) {
            this.hideErrors();
            for (var _i = 0, singleOptinElements_1 = singleOptinElements; _i < singleOptinElements_1.length; _i++) {
                var singleOptinElement = singleOptinElements_1[_i];
                singleOptinElement.addEventListener('click', function (event) {
                    var queryElements = {};
                    var target = event.target.closest('.sna-page-form') || element;
                    Object.keys(_this.gd.fieldSelectors).forEach(function (field) {
                        queryElements[field] = target.querySelectorAll(_this.gd.fieldSelectors[field].fieldClass);
                    });
                    var invalidFields = _this.invalidFields(target);
                    if (!invalidFields.length) {
                        _this.onSingleOptinClickEvent.emit(_this.getUserOptinInfo(queryElements));
                    }
                    else {
                        _this.onInvalidUserInfo.emit(invalidFields);
                        _this.showErrors(invalidFields);
                    }
                });
            }
        }
    };
    AssetManagerComponent.prototype.bindOnDoubleOptinClickEvent = function (element, doubleOptinElements) {
        var _this = this;
        if (doubleOptinElements.length) {
            this.hideErrors();
            for (var _i = 0, doubleOptinElements_1 = doubleOptinElements; _i < doubleOptinElements_1.length; _i++) {
                var doubleOptinElement = doubleOptinElements_1[_i];
                doubleOptinElement.addEventListener('click', function (event) {
                    var queryElements = {};
                    var target = event.target.closest('.sna-page-form') || element;
                    Object.keys(_this.gd.fieldSelectors).forEach(function (field) {
                        queryElements[field] = target.querySelectorAll(_this.gd.fieldSelectors[field].fieldClass);
                    });
                    var invalidFields = _this.invalidFields(target);
                    if (!invalidFields.length) {
                        _this.onDoubleOptinClickEvent.emit(_this.getUserOptinInfo(queryElements));
                    }
                    else {
                        _this.onInvalidUserInfo.emit(invalidFields);
                        _this.showErrors(invalidFields);
                    }
                });
            }
        }
    };
    AssetManagerComponent.prototype.getUserOptinInfo = function (queryElements) {
        var _this = this;
        var event = new UserOptinInfo();
        Object.keys(this.gd.fieldSelectors).forEach(function (field) {
            event[field] = _this.getSelectorObjValue(queryElements[field]);
        });
        event.requiredFields = this.getRequiredFields();
        return event;
    };
    AssetManagerComponent.prototype.disableOptin = function () {
        var element = this.assetManagerElement.nativeElement;
        if (element) {
            var snaFormElement = element.querySelector('.sna-page-form');
            if (snaFormElement) {
                snaFormElement['onsubmit'] = function () {
                    return false;
                };
            }
        }
    };
    AssetManagerComponent.prototype.hideErrors = function () {
        var _this = this;
        Object.keys(this.gd.fieldSelectors).forEach(function (field) {
            _this.hideError(field);
        });
    };
    AssetManagerComponent.prototype.hideError = function (field) {
        var element = this.assetManagerElement.nativeElement;
        var errorDisplay = element.querySelectorAll(this.gd.fieldSelectors[field].errorClass);
        if (errorDisplay.length) {
            for (var _i = 0, errorDisplay_1 = errorDisplay; _i < errorDisplay_1.length; _i++) {
                var error = errorDisplay_1[_i];
                error.style.display = 'none';
            }
        }
    };
    AssetManagerComponent.prototype.showErrors = function (invalidFields) {
        var _this = this;
        var element = this.assetManagerElement.nativeElement;
        invalidFields.forEach(function (invalidField) {
            var errorDisplay = element.querySelectorAll(_this.gd.fieldSelectors[invalidField.field].errorClass);
            if (errorDisplay.length) {
                for (var _i = 0, errorDisplay_2 = errorDisplay; _i < errorDisplay_2.length; _i++) {
                    var error = errorDisplay_2[_i];
                    error.style.display = 'block';
                }
            }
        });
    };
    AssetManagerComponent.prototype.populateUserInfo = function () {
        var _this = this;
        if (this.gd.user) {
            Object.keys(this.gd.fieldSelectors).forEach(function (field) {
                _this.setUserInfo(field, _this.gd.fieldSelectors[field].fieldClass);
            });
        }
    };
    AssetManagerComponent.prototype.setUserInfo = function (field, querySelector) {
        if (this.gd.user[field] && this.gd.user[field].trim()) {
            var element = this.assetManagerElement.nativeElement;
            if (element.querySelector(querySelector)) {
                element.querySelector(querySelector).value = this.gd.user[field];
                element.querySelector(querySelector).disabled = true;
            }
        }
    };
    AssetManagerComponent.prototype.invalidFields = function (target) {
        var _this = this;
        var result = [];
        Object.keys(this.gd.fieldSelectors).forEach(function (field) {
            var selector = target.querySelectorAll(_this.gd.fieldSelectors[field].fieldClass);
            if (!_this.isFieldValid(selector, field)) {
                result.push({ selector: selector, field: field });
            }
        });
        return result;
    };
    AssetManagerComponent.prototype.isFieldValid = function (selector, field) {
        var value = this.getSelectorObjValue(selector);
        if (this.selectorObjIsRequired(selector)) {
            if (!value) {
                return false;
            }
        }
        if ((value && value.trim()) || field === 'email') {
            if (field === 'email') {
                return this.utilityService.isValidEmail(value);
            }
            else if (field === 'zipcode') {
                return this.utilityService.isValidZipcode(value);
            }
            else if (field === 'phoneNumber') {
                return this.utilityService.isValidPhoneNumber(value);
            }
        }
        return true;
    };
    AssetManagerComponent.prototype.getSelectorObjValue = function (selector) {
        if (!selector) {
            return null;
        }
        else {
            if (selector && selector.length > 0) {
                var obj = selector[0];
                if (obj.value && obj.value.trim()) {
                    return obj.value.trim();
                }
                else {
                    return null;
                }
            }
            else {
                return null;
            }
        }
    };
    AssetManagerComponent.prototype.selectorObjIsRequired = function (selector) {
        if (!selector) {
            return false;
        }
        else {
            if (selector && selector.length > 0) {
                var obj = selector[0];
                return obj.required;
            }
            else {
                return false;
            }
        }
    };
    return AssetManagerComponent;
}());
export { AssetManagerComponent };
