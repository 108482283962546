/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../components/asset-manager/asset-manager.component.ngfactory";
import * as i3 from "../../components/asset-manager/asset-manager.component";
import * as i4 from "../../common/util/globaldata.service";
import * as i5 from "../../common/util/utils.service";
import * as i6 from "./site-manager.service";
import * as i7 from "@angular/common/http";
import * as i8 from "./site-manager.component";
import * as i9 from "@angular/platform-browser";
import * as i10 from "../../services/tracker-service";
import * as i11 from "../../services/logger.service";
import * as i12 from "../../services/externalJsSupport.service";
var styles_SiteManagerComponent = [];
var RenderType_SiteManagerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SiteManagerComponent, data: {} });
export { RenderType_SiteManagerComponent as RenderType_SiteManagerComponent };
function View_SiteManagerComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "img", [["src", "assets/images/loading-md.gif"]], null, null, null, null, null))], null, null); }
export function View_SiteManagerComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SiteManagerComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "asset-manager", [], null, [[null, "onNextPageClickEvent"], [null, "onSingleOptinClickEvent"], [null, "onDoubleOptinClickEvent"], [null, "onInvalidUserInfo"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onNextPageClickEvent" === en)) {
        var pd_0 = (_co.onNextPageClickEvent($event) !== false);
        ad = (pd_0 && ad);
    } if (("onSingleOptinClickEvent" === en)) {
        var pd_1 = (_co.onSingleOptinClickEvent($event) !== false);
        ad = (pd_1 && ad);
    } if (("onDoubleOptinClickEvent" === en)) {
        var pd_2 = (_co.onDoubleOptinClickEvent($event) !== false);
        ad = (pd_2 && ad);
    } if (("onInvalidUserInfo" === en)) {
        var pd_3 = (_co.onInvalidUserInfo($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i2.View_AssetManagerComponent_0, i2.RenderType_AssetManagerComponent)), i0.ɵdid(4, 114688, null, 0, i3.AssetManagerComponent, [i4.GlobalDataService, i5.UtilityService], { htmlContent: [0, "htmlContent"] }, { onNextPageClickEvent: "onNextPageClickEvent", onSingleOptinClickEvent: "onSingleOptinClickEvent", onDoubleOptinClickEvent: "onDoubleOptinClickEvent", onInvalidUserInfo: "onInvalidUserInfo" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isProcessing; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.htmlContent; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_SiteManagerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "site-manager", [], null, null, null, View_SiteManagerComponent_0, RenderType_SiteManagerComponent)), i0.ɵprd(4608, null, i1.LocationStrategy, i1.PathLocationStrategy, [i1.PlatformLocation, [2, i1.APP_BASE_HREF]]), i0.ɵprd(4608, null, i1.Location, i1.Location, [i1.LocationStrategy]), i0.ɵprd(512, null, i6.SiteManagerService, i6.SiteManagerService, [i7.HttpClient, i4.GlobalDataService, i5.UtilityService]), i0.ɵdid(4, 114688, null, 0, i8.SiteManagerComponent, [i6.SiteManagerService, i5.UtilityService, i4.GlobalDataService, i9.Title, i10.TrackerService, i11.LoggerService, i7.HttpClient, i12.ExternalJsSupportService], null, null)], function (_ck, _v) { _ck(_v, 4, 0); }, null); }
var SiteManagerComponentNgFactory = i0.ɵccf("site-manager", i8.SiteManagerComponent, View_SiteManagerComponent_Host_0, {}, {}, []);
export { SiteManagerComponentNgFactory as SiteManagerComponentNgFactory };
