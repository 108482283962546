import {OptInRequest} from './optin-request';

/**
 * Created by lsubramaniam on 11/2/17.
 */

export default class OptInRequestModel implements OptInRequest {
  email: string;
  firstName?: string;
  lastName?: string;
  address?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  country?: string;
  phone?: string;
  demoDateTime?: Date;
  emailTemplateUrl: string;
  emailSubject: string;
  pubCode: string;
  level: string;
  brandId: string;
  assetId: string;
  requiredFields: [string];
  externalChannel: string;
  doubleOptInParams: string;
  coiParams: string;
  campaignId: string;
  effortId: string;
  baseUrl: string;
  campaignType: string;
  placementId: string;
  isSendOnlyEmail: boolean;
  authOptIn: boolean;
  coiOptin: boolean;
  utm_campaign: string;
  utm_content: string;
  utm_medium: string;
  utm_source: string;
  utm_term: string;
  eventType: string;
  source: string;
  offerChainId: string;
  emailJobId?: string;
  eventTrigger?: string;
  ipAddress?: string;
  childEletter?: boolean;
  success?: boolean;
  constructor() {
  }
}
