/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./containers/site-manager/site-manager.component.ngfactory";
import * as i3 from "@angular/common";
import * as i4 from "./containers/site-manager/site-manager.service";
import * as i5 from "@angular/common/http";
import * as i6 from "./common/util/globaldata.service";
import * as i7 from "./common/util/utils.service";
import * as i8 from "./containers/site-manager/site-manager.component";
import * as i9 from "@angular/platform-browser";
import * as i10 from "./services/tracker-service";
import * as i11 from "./services/logger.service";
import * as i12 from "./services/externalJsSupport.service";
import * as i13 from "./app.component";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["style", "text-align:center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "site-manager", [], null, null, null, i2.View_SiteManagerComponent_0, i2.RenderType_SiteManagerComponent)), i1.ɵprd(4608, null, i3.LocationStrategy, i3.PathLocationStrategy, [i3.PlatformLocation, [2, i3.APP_BASE_HREF]]), i1.ɵprd(4608, null, i3.Location, i3.Location, [i3.LocationStrategy]), i1.ɵprd(512, null, i4.SiteManagerService, i4.SiteManagerService, [i5.HttpClient, i6.GlobalDataService, i7.UtilityService]), i1.ɵdid(5, 114688, null, 0, i8.SiteManagerComponent, [i4.SiteManagerService, i7.UtilityService, i6.GlobalDataService, i9.Title, i10.TrackerService, i11.LoggerService, i5.HttpClient, i12.ExternalJsSupportService], null, null)], function (_ck, _v) { _ck(_v, 5, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i13.AppComponent, [i6.GlobalDataService, i11.LoggerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i13.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
